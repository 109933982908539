
import { defineComponent } from "vue-demi";
import InfoLegal from "@/components/info/Legal.vue";

export default defineComponent({
  name: "InfoLegalView",
  components: {
    InfoLegal,
  },
});
